import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import FileBase from 'react-file-base64';
import { useDispatch, useSelector} from 'react-redux';
import useStyles from './styles';
import { createPost, updatePost } from '../../actions/posts';
import  { useHistory}  from 'react-router-dom';



    
const Form = ({ currentId, setCurrentId}) => {
    const [postData, setPostData] = useState ({ title: '', message: '', tags: '', selectedFile: '' });
    const post = useSelector((state) => currentId ? state.posts.posts.find((p) => p._id === currentId) : null); //if the ID is there then its an update and find just that post to update form field with existing data 
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'));
    const history = useHistory();

    useEffect(() => {
        if(post) setPostData(post);
    }, [post])
    
    

const handleSubmit = (e) => {
    e.preventDefault(); //prevents browser refresh

    if(currentId) { //if there is an ID then update post, if not then create a post
        dispatch(updatePost(currentId, { ...postData, name: user?.result?.name }));
        
    } else {
        dispatch(createPost({ ...postData, name: user?.result?.name }, history));
    }

    clear();
}

const clear = () => {
    setCurrentId(null);
    setPostData({ title: '', message: '', tags: '', selectedFile: ''});
}

if (!user?.result?.name) {
    return (
        <Paper className={classes.paper}>
            <Typography variant="h6" align="center">
                Please sign in!
                </Typography>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper} elevation={6}>
      <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
        <Typography variant="h6">{currentId ? `Editing "${post.title}"` : 'Create an Itinerary'}</Typography>
        <TextField name="title" variant="outlined" label="Title" fullWidth value={postData.title} onChange={(e) => setPostData({ ...postData, title: e.target.value })} />
        <TextField name="message" variant="outlined" label="Message" fullWidth multiline rows={4} value={postData.message} onChange={(e) => setPostData({ ...postData, message: e.target.value })} />
        <TextField name="tags" variant="outlined" label="Tags (comma separated)" fullWidth value={postData.tags} onChange={(e) => setPostData({ ...postData, tags: e.target.value.split(',') })} />
        <div className={classes.fileInput}><FileBase type="file" multiple={false} onDone={({ base64 }) => setPostData({ ...postData, selectedFile: base64 })} /></div>
        <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" fullWidth>Submit</Button>
        <Button variant="contained" color="secondary" size="small" onClick={clear} fullWidth>Clear</Button>
      </form>
    </Paper>
  );
};

export default Form;