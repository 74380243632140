
import React, { useState, useEffect } from 'react';
import { AppBar, Typography, Toolbar, Avatar, Button } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';

import cmpfyr_logo from '../../images/cmpfyr_logo.png'  //   ../to go back a folder
import * as actionType from '../../constants/actionTypes';
import useStyles from './styles';


const Navbar = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));  //get user from browser cache
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();

    const logout = () => {
        dispatch({ type: actionType.LOGOUT });
    
        history.push('/auth');
    
        setUser(null);
      };

    useEffect(() => {
        const token = user?.token;
    
        if (token) {
          const decodedToken = decode(token);
    
          if (decodedToken.exp * 1000 < new Date().getTime()) logout();
        }
    
        setUser(JSON.parse(localStorage.getItem('profile')));
      }, [location]); //if page (location) changes then update the username in the navbar so dont have to refresh page

      return (
        <AppBar className={classes.appBar} position="static" color="inherit">
          <div className={classes.brandContainer}>
            <img className={classes.image} src={cmpfyr_logo} alt="icon" height="60" />
            <Typography component={Link} to="/" className={classes.heading} variant="h2" align="center">kmpfyr</Typography>
          </div>
          <Toolbar className={classes.toolbar}>
            {user?.result ? (
              <div className={classes.profile}>
                <Avatar className={classes.purple} alt={user?.result.name} src={user?.result.imageUrl}>{user?.result.name.charAt(0)}</Avatar>
                <Typography className={classes.userName} variant="h6">
                  <Link to={`/creators/${user?.result.name}`} style={{ textDecoration: 'none', color: '#3f51b5' }}>
                    {` ${user?.result.name}`}
                  </Link>
                </Typography>
                <Button variant="contained" className={classes.logout} color="secondary" onClick={logout}>Logout</Button>
              </div>
            ) : (
              <Button component={Link} to="/auth" variant="contained" color="primary">Log In</Button>
            )}
          </Toolbar>
        </AppBar>
      );
    };
    
    export default Navbar;