// import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { Avatar, Button, Paper, Grid, Typography, Container, TextField } from '@material-ui/core';
// import { useHistory } from 'react-router-dom';
// import { GoogleLogin } from 'react-google-login';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
// import Icon from './icon';
// import { signin, signup } from '../../actions/auth';
// import Input from './Input';
// import useStyles from './styles';

// const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '' };

// const Auth = () => {
//     const classes = useStyles();
//     const [showPassword, setShowPassword] = useState(false);
//     const [isSignup, setIsSignup] = useState(false);
//     //const [formData, setFormData] = useState(initialState);
//     const [form, setForm] = useState(initialState);
//     const dispatch = useDispatch();
//     const history = useHistory();

//     const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);  //toggle show password (if off then on; if on then off)

//     const handleSubmit = (e) => {
//       e.preventDefault();  //browser defaults to refresh. this function prevents that

//       if (isSignup) {
//         dispatch(signup(form, history));
//       } else {
//         dispatch(signin(form, history));
//       }
//     };

//     const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

//     const switchMode = () => {
//         // setForm(initialState);
//          setIsSignup((prevIsSignup) => !prevIsSignup);  //toggle
//         setShowPassword(false);
//       };

//       const googleSuccess = async (res) => {
//         const result = res?.profileObj;  //res? means it will get an undefined
//         const token = res?.tokenId;
    
//         try {
//           dispatch({ type: 'AUTH', data: { result, token } });
    
//           history.push('/');
//         } catch (error) {
//           console.log(error);
//         }
//       };
//     //video 3:19:20
//       const googleError = () => console.log('Google Sign In was unsuccessful. Try again later');

//     return (
//         <Container component="main" maxWidth="xs">
//             <Paper className={classes.paper} elevation={3}>
//                 <Avatar className={classes.avatar}>
//                     <LockOutlinedIcon />
//                 </Avatar>
//                 <Typography variant="h5">{isSignup ? 'Sign Up' : 'Log In'}</Typography>
//                 <form className={classes.form} onSubmit={handleSubmit}>
//                     <Grid container spacing={2}>
//                         {isSignup && (
//                                 <>
//                                     <Input name="firstName" label="First Name" handleChange={handleChange} autoFocus half/>
//                                     <Input name="lastName" label="Last Name" handleChange={handleChange} half/>
//                                 </>
//                             )}
//                         <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
//                         <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
//                         { isSignup && <Input name="confirmPassword" label="Re-enter Password" handleChange={handleChange} type="password" /> }
//                     </Grid>
//                      <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
//                         { isSignup ? 'Sign Up' : 'Sign In' }
//                     </Button>
//                     <GoogleLogin
//                         clientId="564033717568-bu2nr1l9h31bhk9bff4pqbenvvoju3oq.apps.googleusercontent.com"
//                         render={(renderProps) => (
//                         <Button className={classes.googleButton} color="primary" fullWidth onClick={renderProps.onClick} disabled={renderProps.disabled} startIcon={<Icon />} variant="contained">
//                             Sign In with Google
//                         </Button>
//                         )}
//                         onSuccess={googleSuccess}
//                         onFailure={googleError}
//                         cookiePolicy="single_host_origin"
//                     />
//                   <Grid container justify="flex-end">
//                       <Button onClick={switchMode}>
//                         { isSignup ? 'Already have an account? Log in' : "Don't have an account? Sign Up" }
//                       </Button>
//                   </Grid>
//                 </form>
//             </Paper>
//         </Container>

//     );
// };

// export default Auth;


import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Button, Paper, Grid, Typography, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import Icon from './icon';
import { signin, signup } from '../../actions/auth';
import { AUTH } from '../../constants/actionTypes';
import useStyles from './styles';
import Input from './Input';

const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '' };

const Auth = () => {
  const [form, setForm] = useState(initialState);
  const [isSignup, setIsSignup] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const switchMode = () => {
    setForm(initialState);
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSignup) {
      dispatch(signup(form, history));
    } else {
      dispatch(signin(form, history));
    }
  };

  const googleSuccess = async (res) => {
    const result = res?.profileObj;
    const token = res?.tokenId;

    try {
      dispatch({ type: AUTH, data: { result, token } });

      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };
//     //video 3:19:20
  //const googleError = () => alert('Google Sign In was unsuccessful. Try again later');
  const googleError = () => console.log('Google Sign In was unsuccessful. Try again later');

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper} elevation={3}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">{ isSignup ? 'Sign up' : 'Log in' }</Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            { isSignup && (
            <>
              <Input name="firstName" label="First Name" handleChange={handleChange} autoFocus half />
              <Input name="lastName" label="Last Name" handleChange={handleChange} half />
            </>
            )}
            <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
            <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
            { isSignup && <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type="password" /> }
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            { isSignup ? 'Sign Up' : 'Log In' }
          </Button>
          <GoogleLogin
            clientId="985234668598-9u1d4ooq3ddnoed918qpguvaleqm9qbh.apps.googleusercontent.com"
            render={(renderProps) => (
              <Button className={classes.googleButton} color="primary" fullWidth onClick={renderProps.onClick} disabled={renderProps.disabled} startIcon={<Icon />} variant="contained">
                Google Sign In
              </Button>
            )}
            onSuccess={googleSuccess}
            onFailure={googleError}
            cookiePolicy="single_host_origin"
          />
          <Grid container justify="flex-end">
            <Grid item>
              <Button onClick={switchMode}>
                { isSignup ? 'Already have an account? Sign in' : "Don't have an account? Sign Up" }
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default Auth;